import { useState, useEffect, useCallback } from "react";

interface Props {
  timeFrom: string;
  timeUpto: string;
  slotDuration: number;
}

export const useCountdownTimer = ({
  timeUpto,
  timeFrom,
  slotDuration,
}: Props) => {
  const [time, setTime] = useState({
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0",
  });
  const [currentRange, setCurrentRange] = useState<{
    currentSlotStartDate: Date;
    currentSlotEndDate: Date;
  } | null>(null);

  const getCurrentSlot = () => {
    const startDate = new Date(timeFrom);
    const endDate = new Date(timeUpto);
    const now = new Date();

    let currentSlotStartDate = startDate;
    let currentSlotEndDate = endDate;
    for (
      let d = startDate;
      d <= endDate;
      d.setDate(d.getDate() + slotDuration)
    ) {
      const currentDate = new Date(d);
      const nextDate = new Date(
        currentDate.setDate(currentDate.getDate() + slotDuration)
      );
      if (d <= now && now < nextDate) {
        currentSlotStartDate = d;
        currentSlotEndDate = nextDate;
        break;
      }
    }
    return { currentSlotStartDate, currentSlotEndDate };
  };

  useEffect(() => {
    setCurrentRange(getCurrentSlot());
  }, []);

  const convertSingleDigitToDoubleDigit = (number: number) => {
    if (number < 10) return `0${number}`;
    return `${number}`;
  };

  const intevalFunction = useCallback(() => {
    const startDate = new Date(currentRange!.currentSlotEndDate);
    const now = new Date();
    const diff = startDate.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    setTime({
      days: convertSingleDigitToDoubleDigit(days),
      hours: convertSingleDigitToDoubleDigit(hours),
      minutes: convertSingleDigitToDoubleDigit(minutes),
      seconds: convertSingleDigitToDoubleDigit(seconds),
    });
  }, [timeUpto, currentRange]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> = setInterval(() => {
      if (currentRange) {
        intevalFunction();
      }
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [intevalFunction, currentRange]);

  return {
    time,
  };
};
