export const staticPageSeoData = {
  seoTitle: "Jumkey.com - House of Fashion Jewellery",
  seoDescription:
    "Shop for latest trends in fashion jewellery for women. Cash on delivery available. Free and Easy Returns.",
  seoCanonicalUrl: "https://www.jumkey.com",
  openGraphTitle: "Jumkey.com - House of Fashion Jewellery",
  openGraphDescription:
    "Shop for latest trends in fashion jewellery for women. Cash on delivery available. Free and Easy Returns.",
  openGraphImages:
    "https://cdn.picpil.com/logos/2022/05/147/1242566_original.jpg",
  openGraphVideos: null,
  openGraphType: "Page",
  openGraphLocale: "en_IN",
  openGraphSite: "https://www.jumkey.com",
  openGraphUrl: "https://www.jumkey.com",

  facebookAppId: 839118579472829,

  robotsProps: {
    maxSnippet: -1,
    maxImagePreview: "none",
    maxVideoPreview: -1,

    notranslate: true,
    nosnippet: true,
    noarchive: true,
    noimageindex: true,
  },

  additionalMetaTags: [
    {
      property: "dc:creator",
      content: "Ninjaas Labs Private Limited",
    },
    {
      property: "application-name",
      content: "Jumkey",
    },
  ],
  noFollow: false,
  mobileAlternateMedia: null,
  mobileAlternateHref: null,

  noIndex: false,
  keywords: null,
  additionalLinkTags: null,

  twitterSite: null,
  twitterCardType: null,
  twitterHandle: null,
};
