import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import { NextPage } from "next";
import { groq } from "next-sanity";

import { getRowProductsForProductCarousel } from "../utils";
import { BannerCarousel, ProductCarousel } from "../src/organisms";
import { ImageCaoursel } from "../src/molecules";
import { useCountdownTimer } from "../src/hooks/useCountdownTimer";
import { SEO } from "../src/atoms";
import { staticPageSeoData } from "../src/data/static-page-seo-data";

const getGroqQuery = () => {
  return groq`*[_type == "staticPage" && title == "home-page"][0]`;
};

interface Props {
  timeFrom: string;
  timeUpto: string;
  slotDuration: number;
}

const TimerSubHeader: React.FC<Props> = props => {
  const { time } = useCountdownTimer(props);
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      fontSize={{ xs: "12px", sm: "14px" }}>
      Sale ends in
      <Typography
        variant="body2"
        fontSize={{ xs: "12px", sm: "14px" }}
        fontWeight={600}
        color="error">
        {time.hours} Hrs : {time.minutes} Min : {time.seconds} Sec
      </Typography>
    </Typography>
  );
};

const HomePage: NextPage<any> = props => {
  if (!props.children) {
    return null;
  }
  const getChildren = () => {
    const { children, seo } = props;
    let updatedSeo = { ...staticPageSeoData };
    if (seo) {
      updatedSeo = { ...updatedSeo, ...seo };
    }

    return children.map((child: any, index: number) => {
      const getHeader = () => {
        const { title, subtitle, timer, actionLabel, contentHandle } =
          child.heading as any;
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start">
            <Stack>
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "14px", sm: "16px" }}>
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  fontSize={{ xs: "12px", sm: "14px" }}
                  color="text.secondary">
                  {subtitle}
                </Typography>
              )}
              {timer && (
                <TimerSubHeader
                  slotDuration={timer.slotDuration}
                  timeFrom={timer.timeFrom}
                  timeUpto={timer.timeUpto}
                />
              )}
            </Stack>
            {contentHandle && (
              <Link
                href={contentHandle}
                fontSize={{ xs: "14px", sm: "16px" }}
                underline="none"
                textTransform="capitalize">
                {actionLabel ? actionLabel : "View All"}
              </Link>
            )}
          </Stack>
        );
      };
      const getElement = () => {
        switch (child._type) {
          case "bannerCarousel":
            return <BannerCarousel key={child._key} items={child.items} />;
          case "imageCaoursel":
            return (
              <ImageCaoursel
                key={child._key}
                items={child.items}
                itemsSize={child.itemSize}
              />
            );
          case "productCarousel":
            return (
              <ProductCarousel key={child._key} products={child.products} />
            );
          case "imageGrid":
            return (
              <Grid
                key={child._key}
                container
                spacing={1}
                columns={child.numberOfColumns}>
                {child.items.map((item: any, index: number) => {
                  return (
                    <Grid item key={index} {...item.colspan}>
                      <Box
                        component="img"
                        src={item.desktopImageUrl}
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          display: { xs: "none", sm: "block" },
                        }}
                      />
                      <Box
                        component="img"
                        src={item.mobileImageUrl}
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          display: { xs: "block", sm: "none" },
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            );
        }
      };
      return (
        <>
          <SEO seo={updatedSeo} />
          <Stack gap={2}>
            {child.showTitle && getHeader()}
            {getElement()}
          </Stack>
        </>
      );
    });
  };

  return (
    <Container maxWidth="xl">
      <Stack gap={props.rowGap}>{getChildren()}</Stack>
    </Container>
  );
};

export const getStaticProps = async () => {
  const res = await fetch(
    "https://sanity-json-file-storage.s3.ap-south-1.amazonaws.com/staticPage/home-page.json"
  );
  const data = await res.json();
  let updatedData = {};
  if (data && data.children && data.children.length > 0) {
    updatedData = await getRowProductsForProductCarousel(data);
  }

  return {
    props: updatedData,
    revalidate: 300,
  };
};

export default HomePage;
